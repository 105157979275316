import React from 'react';

const YollaAdsComponent = (props) => {
    return (
      <>
        <div class="ympb_target" data-type="desktop-medrec-template" data-subtype="1000x250"></div>
      </>
    );
};

export default YollaAdsComponent;
